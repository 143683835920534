import React, { useState, useEffect, useContext, useReducer } from "react";
import Head from "../../layout/head/Head";
import Content from "../../layout/content/Content";
import DatePicker from "react-datepicker";
import swal from "sweetalert2";
import classnames from "classnames";
import {
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown,
  DropdownItem,
  Badge,
  Modal,
  ModalBody,
  ModalHeader,
  ModalFooter,
  Nav,
  NavItem,
  TabContent,
  TabPane,
  NavLink,
  Table,
  Tooltip,
  Collapse,
  Pagination,
  PaginationItem,
  PaginationLink,
} from "reactstrap";
import {
  Block,
  BlockHead,
  BlockBetween,
  BlockHeadContent,
  BlockTitle,
  BlockDes,
  Icon,
  Button,
  Col,
  UserAvatar,
  PaginationComponent,
  DataTable,
  DataTableBody,
  DataTableHead,
  DataTableRow,
  DataTableItem,
  RSelect,
  CodeBlock,
} from "../../components/Component";
import {
  findUpper,
  setDeadline,
  setDeadlineDays,
  calcPercentage,
} from "../../utils/Utils";
import { useForm } from "react-hook-form";
import { Link, Redirect, useHistory } from "react-router-dom";
import {
  CampaignList,
  createDuplicateCampaign,
  deleteCampaign,
  getCampaignInfo,
  getWebsiteList,
  reSubmitAdUnit,
  updateAllCampaign,
  updateCampaignStatus,
  resubWebsite
} from "../../app/api";
import { toast, ToastContainer } from "react-toastify";
import Loader from "../../app/Loader";
import CopyLoader from "../../app/CopyLoader";
import AppContext from "../../context/AppContext";
import ReportComponent from "../ReportComponent";

export const Websites = () => {
  const ctx = useContext(AppContext);
  let history = useHistory();

  const getAdStatus = () => {
    let adt = "";
    if (adStatus == "In Review") {
      adt = 1;
    } else if (adStatus == "Active") {
      adt = 2;
    } else if (adStatus == "Hold") {
      adt = 3;
    } else if (adStatus == "Active") {
      adt = 4;
    } else if (adStatus == "Suspended") {
      adt = 5;
    } else if (adStatus == "Rejected") {
      adt = 6;
    } else if (adStatus == "Inactive") {
      adt = 7;
    } 
    return adt;
  };

  const [sm, updateSm] = useState(false);

  const [loading, setLoading] = useState(false);
  const [cload, setCload] = useState(false);

  const [stat, setStat] = useState(false);
  const [webId, setWebId] = useState("");
  const [webName, setWebName] = useState("");

  const [adUniId, setAdUniId] = useState("");
  const [adUniName, setAdUniName] = useState("");

  const [adId, setAdId] = useState("");
  const [adType, setAdType] = useState("");
  const [adSize, setAdSize] = useState("");
  const [gridType, setGridType] = useState("");
  const [adCode, setAdCode] = useState("");
  const [initCode, setInitCode] = useState("");

  const [data, setData] = useState([]);
  const [pgs, setPgs] = useState(0);
  const [pg, setPg] = useState(0);

  const [currentPage, setCurrentPage] = useState(1);
  const [itemPerPage, setItemPerPage] = useState(10);
  const [adStatus, setAdStatus] = useState("");
  const [modalTab, setModalTab] = useState(false);
  const toggleTab = () => setModalTab(!modalTab);

  const getWebData = async (sts = "", src = "", pg = 0, lim = 0) => {
    setLoading(true);

    let stat = sts > 0 ? sts : sts == 0 ? "" : getAdStatus(sts);
    let itemLim = lim > 0 ? lim : itemPerPage;

    let pgn = pg === 0 ? currentPage : pg;

    let src2 = !src && src != 0 ? skey : src == 0 ? "" : src;
    let res = await getWebsiteList(stat, src2, pgn, itemLim);
    if (res.code == 200) {
      setData(res.data);
      setPgs(res.row);
    } else {
      setPgs(0);
      setData([]);
    }
    ctx.dispatch({
      type: "wlt",
      wlt: res.wallet,
    });
    setLoading(false);
  };

  const [skey, setSkey] = useState("");

  const onCampSearch = (e) => {
    let src = e.target.value;
    // console.log(src)
    let src2 = src.length > 0 ? src : 0;
    setSkey(src);
    let sts = getAdStatus();

    getWebData(sts, src2);
  };

  const [isOpen, setIsOpen] = useState("0");

  const toggleCollapse = (param) => {
    if (param === isOpen) {
      setIsOpen("0");
    } else {
      setIsOpen(param);
    }
  };

  const capitalizeFirstLetter = (word) => {
    return word.charAt(0).toUpperCase() + word.slice(1);
  }

  const reSubmit = async (adid) => {
    setLoading(true);
    let uid = localStorage.getItem("uid");
    let res = await reSubmitAdUnit({
      uid: uid,
      ad_code: adid,
    });

    if (res.code == 200) {
      getWebData();
      toast.success("Ad Unit Activated Successfully!", {
        position: "top-right",
        autoClose: true,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: false,
        // closeButton: <CloseButton />,
      });
    } 
    setLoading(false);
  };

  const paginate = (pageNumber) => {
    setCurrentPage(pageNumber);
    if (currentPage !== pageNumber) {
      let sts = getAdStatus();
      getWebData(sts, skey, pageNumber);
    }
  };

  const resubmitWebsite = async(data) => {
    let uid = localStorage.getItem("uid");
    let response = await resubWebsite({
      uid: uid,
      web_id: data.web_code,
      action: 'resubmit'
    });
    if (response.code == 200) {
      getWebData();
      toast.success("Website Re-submitted Successfully!", {
        position: "top-right",
        autoClose: true,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: false,
      });
    } else {
      toast.error("Website Re-submitted Failed!", {
        position: "top-right",
        autoClose: true,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: false,
      });
    }
    setLoading(false);
  };

  useEffect(() => {
    getWebData();
  }, []);

  return (
    <>
      <React.Fragment>
        {stat ? (
          <ReportComponent
            setStat={setStat}
            website={webId}
            website_name={webName}
            adunit_id={adUniId}
            setAdUniId={setAdUniId}
            setAdUniName={setAdUniName}
            adunit_name={adUniName}
          />
        ) : (
          <>
            <Head title="My Websites"></Head>
            <Content>
              <Loader visible={loading} />
              <CopyLoader visible={cload} />
              <BlockHead size="sm">
                <BlockBetween>
                  <BlockHeadContent>
                    <BlockTitle page> Websites</BlockTitle>
                    {ctx.sts.perms.includes('413') || ctx.sts.perms.includes('9999') ? 
                    <BlockDes className="text-soft">
                      You have total {pgs} websites
                    </BlockDes>
                    : ''}
                  </BlockHeadContent>
                  <BlockHeadContent>
                    <div className="toggle-wrap nk-block-tools-toggle">
                      <Button
                        className={`btn-icon btn-trigger toggle-expand mr-n1 ${
                          sm ? "active" : ""
                        }`}
                        onClick={() => updateSm(!sm)}
                      >
                        <Icon name="menu-alt-r"></Icon>
                      </Button>
                      <div
                        className="toggle-expand-content"
                        style={{ display: sm ? "block" : "none" }}
                      >
                        <ul className="nk-block-tools  g-3">
                         {ctx.sts.perms.includes('413') || ctx.sts.perms.includes('9999') ? 
                          <li>
                            <div className="form-control-wrap">
                              <div className="form-icon form-icon-right">
                                <Icon name="search"></Icon>
                              </div>
                              <input
                                type="text"
                                className="form-control"
                                id="default-04"
                                placeholder="Search by Website Name"
                                onChange={(e) => onCampSearch(e)}
                              />
                            </div>
                          </li> 
                          : '' }
                          {ctx.sts.perms.includes('413') || ctx.sts.perms.includes('9999') ? 
                          <li>
                            <UncontrolledDropdown>
                              <DropdownToggle
                                tag="a"
                                className="dropdown-toggle btn btn-white btn-dim btn-outline-light"
                              >
                                <Icon
                                  name="filter-alt"
                                  className="d-none d-sm-inline"
                                ></Icon>
                                <span>By Status {adStatus}</span>
                                <Icon
                                  name="chevron-right"
                                  className="dd-indc"
                                ></Icon>
                              </DropdownToggle>
                              <DropdownMenu right>
                                <ul className="link-list-opt no-bdr">
                                  <li>
                                    <DropdownItem
                                      tag="a"
                                      href="#dropdownitem"
                                      onClick={(ev) => {
                                        ev.preventDefault();
                                        getWebData(0, skey, currentPage);
                                        setAdStatus("");
                                      }}
                                    >
                                      {" "}
                                      <span>All</span>
                                    </DropdownItem>
                                  </li>
                                  <li>
                                    <DropdownItem
                                      tag="a"
                                      href="#dropdownitem"
                                      onClick={(ev) => {
                                        ev.preventDefault();
                                        getWebData(1, skey, currentPage);
                                        setAdStatus("In Review");
                                      }}
                                    >
                                      <span>In Review</span>
                                      {adStatus === "In Review" && (
                                        <Icon
                                          name="check"
                                          style={{
                                            position: "absolute",
                                            right: "10px",
                                            fontSize: "14px",
                                          }}
                                        />
                                      )}
                                    </DropdownItem>
                                  </li>
                                  <li>
                                    <DropdownItem
                                      tag="a"
                                      href="#dropdownitem"
                                      onClick={(ev) => {
                                        ev.preventDefault();
                                        getWebData(4, skey, currentPage);
                                        setAdStatus("Active");
                                      }}
                                    >
                                      <span>Active</span>
                                      {adStatus === "Active" && (
                                        <Icon
                                          name="check"
                                          style={{
                                            position: "absolute",
                                            right: "10px",
                                            fontSize: "14px",
                                          }}
                                        />
                                      )}
                                    </DropdownItem>
                                  </li>
                                  <li>
                                    <DropdownItem
                                      tag="a"
                                      href="#dropdownitem"
                                      onClick={(ev) => {
                                        ev.preventDefault();
                                        getWebData(7, skey, currentPage);
                                        setAdStatus("Inactive");
                                      }}
                                    >
                                      <span>Inactive</span>
                                      {adStatus === "Inactive" && (
                                        <Icon
                                          name="check"
                                          style={{
                                            position: "absolute",
                                            right: "10px",
                                            fontSize: "14px",
                                          }}
                                        />
                                      )}
                                    </DropdownItem>
                                  </li>
                                  <li>
                                    <DropdownItem
                                      tag="a"
                                      href="#dropdownitem"
                                      onClick={(ev) => {
                                        ev.preventDefault();
                                        getWebData(3, skey, currentPage);
                                        setAdStatus("Hold");
                                      }}
                                    >
                                      <span>Hold</span>
                                      {adStatus === "Hold" && (
                                        <Icon
                                          name="check"
                                          style={{
                                            position: "absolute",
                                            right: "10px",
                                            fontSize: "14px",
                                          }}
                                        />
                                      )}
                                    </DropdownItem>
                                  </li>

                                  <li>
                                    <DropdownItem
                                      tag="a"
                                      href="#dropdownitem"
                                      onClick={(ev) => {
                                        ev.preventDefault();
                                        getWebData(5, skey, currentPage);
                                        setAdStatus("Suspended");
                                      }}
                                    >
                                      <span>Suspended </span>
                                      {adStatus === "Suspended" && (
                                        <Icon
                                          name="check"
                                          style={{
                                            position: "absolute",
                                            right: "10px",
                                            fontSize: "14px",
                                          }}
                                        />
                                      )}
                                    </DropdownItem>
                                  </li>
                                  <li>
                                    <DropdownItem
                                      tag="a"
                                      href="#dropdownitem"
                                      onClick={(ev) => {
                                        ev.preventDefault();
                                        getWebData(6, skey, currentPage);
                                        setAdStatus("Rejected");
                                      }}
                                    >
                                      <span>Rejected </span>
                                      {adStatus === "Rejected" && (
                                        <Icon
                                          name="check"
                                          style={{
                                            position: "absolute",
                                            right: "10px",
                                            fontSize: "14px",
                                          }}
                                        />
                                      )}
                                    </DropdownItem>
                                  </li>
                                </ul>
                              </DropdownMenu>
                            </UncontrolledDropdown>
                          </li>
                          : ''}
                          {ctx.sts.perms.includes('414') || ctx.sts.perms.includes('9999') ? 
                          <li className="nk-block-tools-opt">
                            <Link
                              to={`${process.env.PUBLIC_URL + "/ad-website"}`}
                            >
                              <Button color="primary">
                                <Icon name="plus"></Icon>
                                <span>Add New Website</span>
                              </Button>
                            </Link>
                          </li>
                          : ''}
                        </ul>
                      </div>
                    </div>
                  </BlockHeadContent>
                </BlockBetween>
              </BlockHead>
              <Block>
                <div className="accordion accordian-s3">
                  {data.length > 0 && (ctx.sts.perms.includes('413') || ctx.sts.perms.includes('9999')) ? (
                    data.map((item, key) => {
                      return (
                        <>
                          <div className="accordion-item">
                            <div className="accordion-head">
                              <div className="webboxcard">
                                <h6 className="title" style={{ flex: 0.3 }}>
                                  {item.site_url}
                                  {item.status == 1 ? (
                                    <Badge className="badge-dim" style={{background: 'rgb(121, 139, 255)',color:'#fff'}}>
                                      In Review
                                    </Badge>
                                  ) : item.status == 2 ? (
                                    <Badge
                                      className="badge-dim"
                                      color="success"
                                    >
                                      Active
                                    </Badge>
                                  ) : item.status == 3 ? (
                                    <Badge
                                      className="badge-dim"
                                      color="warning"
                                    >
                                      Hold
                                    </Badge>
                                  ) : item.status == 4 ? (
                                    <Badge
                                      className="badge-dim"
                                      color="success"
                                    >
                                      Active
                                    </Badge>
                                  ) : item.status == 5 ? (
                                    <Badge className="badge-dim" color="danger">
                                      Suspended
                                    </Badge>
                                  ) : item.status == 6 ? (
                                    <Badge className="badge-dim" color="danger">
                                      Rejected
                                    </Badge>
                                  ) : item.status == 7 ? (
                                    <Badge className="badge-dim" color="danger">
                                      Inactive
                                    </Badge>
                                  ) : (
                                    ""
                                  )}
                                  <br />
                                  {(item.status === 6) ?  
                                  <span style={{ fontSize: 11, fontWeight: 300 }}>{item.remark}</span> : //For Status Rejected
                                  <span style={{ fontSize: 11, fontWeight: 300 }}>{item.web_code}</span>
                                  }
                                </h6>

                                <span
                                  className="align-items-right"
                                  style={{ flex: 0.4 }}
                                >
                                  <small>
                                    <b>Category</b>
                                  </small>
                                  <br />
                                  {item.cat_name}
                                </span>
                                <span
                                  className="conttrf"
                                  style={{ flex: 0.3 }}
                                >
                                  {item.status == 6 && (
                                    <Link
                                      onClick={() => {
                                        setLoading(true);
                                        resubmitWebsite(item);
                                      }}
                                      className="btn btn-light btn-sm btn-dim mt-1"
                                    >
                                      <Icon name="edit" size="sm" />
                                      &nbsp; Re-submit
                                    </Link>
                                  )}
                                  {item.status == 4 && (
                                    <>
                                    {ctx.sts.perms.includes('420') || ctx.sts.perms.includes('9999') ?
                                      <Button
                                        className="btn-dim mr-1"
                                        size="sm"
                                        color="light"
                                        onClick={() => toggleCollapse(key)}
                                      >
                                        {item.adunits} ad unit(s){" "}
                                      </Button>
                                      :''}
                                      {ctx.sts.perms.includes('416') || ctx.sts.perms.includes('9999') ?
                                      <Button
                                        className="btn-dim btn-icon mr-1"
                                        size="sm"
                                        color="light"
                                        onClick={() => {
                                          setStat(true);
                                          setWebId(item.web_code);
                                          setWebName(item.site_url);
                                        }}
                                      >
                                        <Icon name="bar-fill-c" size="sm" />{" "}
                                        Statistics &nbsp;
                                      </Button>
                                      : ''}
                                      
                                      {ctx.sts.perms.includes('418') || ctx.sts.perms.includes('9999') && item.status == 4 ?
                                        <Link
                                          to={
                                            `${process.env.PUBLIC_URL}/create-ad-unit/` +
                                            item.web_code
                                          }
                                          onClick={() => {
                                            ctx.dispatch({
                                              type: "eroad",
                                              adult_cat: item.website_category,
                                            });
                                          }}
                                          className="btn btn-light btn-sm btn-dim"
                                        >
                                          <Icon name="plus-circle" />
                                          Ad Unit &nbsp;
                                        </Link>
                                      :''}
                                    </>
                                  )}
                                </span>
                              </div>
                            </div>
                            <Collapse
                              className="accordion-body"
                              style={{ background: "#f5f5f5" }}
                              isOpen={isOpen === key ? true : false}
                            >
                              <div className="accordion-inner">
                                <ul>
                                  {item.adunit_list.length > 0
                                    ? item.adunit_list.map((item2, key2) => {
                                        return (
                                          <li
                                            className="pt-2 pb-2"
                                            style={{
                                              borderBottom: "1px solid #ddd",
                                            }}
                                          >
                                            <div className="webboxcard">
                                              <h6
                                                className="title"
                                                style={{
                                                  flex: 0.3,
                                                  fontSize: 14,
                                                  fontWeight: 400,
                                                }}
                                              >
                                                {item2.ad_name}
                                                {item2.badge == 1 && (
                                                  <Badge color="primary" pill>
                                                    New
                                                  </Badge>
                                                )}

                                                <br />
                                                <span
                                                  style={{
                                                    fontSize: 11,
                                                    fontWeight: 300,
                                                  }}
                                                >
                                                  {item2.ad_code}
                                                </span>
                                              </h6>

                                              <span
                                                className="align-items-right"
                                                style={{ flex: 0.4 }}
                                              >
                                                {capitalizeFirstLetter((item2.ad_type == "social" ? "In-Page Push" : item2.ad_type))}
                                              </span>

                                              <span
                                                className="align-items-right"
                                                style={{ flex: 0.3 }}
                                              >
                                                {item2.status == 1 ? (
                                                  <Badge
                                                    className="badge-dim badge-md mr-3"
                                                    color="danger"
                                                  >
                                                    Inactive
                                                  </Badge>
                                                  ) : item2.status == 2 ? (
                                                    <Badge
                                                      className="badge-dim badge-md mr-3"
                                                      color="success"
                                                    >
                                                      Active
                                                    </Badge>
                                                  ) : item2.status == 3 ? (
                                                    <Badge
                                                      className="badge-dim badge-md mr-3"
                                                      color="warning"
                                                    >
                                                      Hold
                                                    </Badge>
                                                  ) : (
                                                    <Badge
                                                      className="badge-dim badge-md mr-3"
                                                      color="danger"
                                                    >
                                                      Suspended
                                                    </Badge>
                                                  )}
                                                  
                                                  {ctx.sts.perms.includes('424') || ctx.sts.perms.includes('9999') ?
                                                  <Button
                                                    className="btn-dim btn-icon mr-3"
                                                    size="sm"
                                                    color="light"
                                                    onClick={() => {
                                                      setWebId(item.web_code);
                                                      setWebName(item.site_url);
                                                      setAdUniId(item2.ad_code);
                                                      setAdUniName(item2.ad_name);
                                                      setStat(true);
                                                    }}
                                                  >
                                                    <Icon
                                                      name="bar-fill-c"
                                                      size="sm"
                                                    />{" "}
                                                    Statistics &nbsp;
                                                  </Button> : ''}
                                                  {item2.status == 1 &&
                                                    item.status == 4 && ctx.sts.perms.includes('9999') || ctx.sts.perms.includes('428') ? (
                                                    <Button
                                                      className="btn btn-icon"
                                                      size="sm"
                                                      color="danger"
                                                      onClick={() => {
                                                        reSubmit(item2.ad_code);
  
                                                        // dispatch({
                                                        //       type:"Update",
                                                        //       adid : item2.ad_code
                                                        //     })
                                                      }}
                                                    >
                                                      {" "}
                                                      &nbsp;
                                                      <Icon name="redo" />{" "}
                                                      Resubmit &nbsp;
                                                    </Button>
                                                  ) : item2.status == 2 &&
                                                  item.status == 4 && ctx.sts.perms.includes('9999') || ctx.sts.perms.includes('430') ? (
                                                    <Button
                                                      className="btn-dim btn-icon"
                                                      size="sm"
                                                      color="light"
                                                      onClick={() => {
                                                        setAdId(item2.ad_code);
                                                        setAdType(item2.ad_type);
                                                        setAdSize(item2.ad_size);
                                                        setGridType(item2.grid_type);
                                                        let adscript = "";
                                                        if (
                                                          item2.ad_type == "text"
                                                        ) {
                                                          adscript =
                                                            "7s-text-ad.js";
                                                          setInitCode(
                                                            "initTextAd"
                                                          );
                                                        }
                                                        if (
                                                          item2.ad_type ==
                                                          "native"
                                                        ) {
                                                          adscript =
                                                            "7s-native-ad.js";
                                                          setInitCode(
                                                            "initNativeAd"
                                                          );
                                                        }
                                                        if (
                                                          item2.ad_type ==
                                                          "social"
                                                        ) {
                                                          adscript =
                                                            "7s-social-ad.js";
                                                          setInitCode(
                                                            "initSocialAd"
                                                          );
                                                        }
                                                        if (
                                                          item2.ad_type ==
                                                          "banner"
                                                        ) {
                                                          adscript =
                                                            "7s-banner-ad.js";
                                                          setInitCode(
                                                            "initBannerAd"
                                                          );
                                                        }
                                                        if (
                                                          item2.ad_type ==
                                                          "popunder"
                                                        ) {
                                                          adscript =
                                                            "7s-popunder.js";
                                                          setInitCode("initAd");
                                                        }
                                                        setAdCode(adscript);
                                                        setModalTab(true);
                                                      }}
                                                    >
                                                      <Icon name="code" /> Get
                                                      Code &nbsp;
                                                    </Button>
                                                  ) : (
                                                    ""
                                                  )}
                                                  {ctx.sts.perms.includes('426') || ctx.sts.perms.includes('9999') ?
                                                  <Link
                                                    to={
                                                      `update-ad-unit/` +
                                                      item2.ad_code
                                                    }
                                                    onClick={() => {
                                                      ctx.dispatch({
                                                        type: "eroad",
                                                        adult_cat:
                                                          item.website_category,
                                                      });
                                                    }}
                                                    className="btn btn-light btn-sm btn-dim btn-icon ml-3"
                                                    size="sm"
                                                    color="light"
                                                  >
                                                    <Icon name="edit" size="sm" />{" "}
                                                    Edit &nbsp;
                                                  </Link> : ''}
                                                </span>
                                              </div>
                                            </li>
                                          );
                                        })
                                      : null}
                                  </ul>
                                </div>
                              </Collapse>
                            </div>
                          </>
                        );
                      })
                    ) : (
                      <h6 className="text-center p-4">{!ctx.sts.perms.includes('413') ? 'You have not website list permission!' : 'No record found!'} </h6>
                    )}
                    {ctx.sts.perms.includes('413') || ctx.sts.perms.includes('9999') ? 
                    <div className="card-inner" style={{ display: "flex" }}>
                      <div style={{ alignSelf: "self-start", width: "97%" }}>
                        <PaginationComponent
                          itemPerPage={itemPerPage}
                          currentPage={currentPage}
                          totalItems={pgs}
                          paginate={paginate}
                        />
                      </div>
                      <div>
                        <UncontrolledDropdown>
                          <DropdownToggle
                            tag="a"
                            className="dropdown-toggle bg-white btn btn-sm btn-outline-light btn-icon"
                          >
                            &nbsp; &nbsp; {itemPerPage}{" "}
                            <Icon name="downward-ios"></Icon>
                          </DropdownToggle>
                          <DropdownMenu right>
                            <ul className="link-list-opt no-bdr">
                              <li onClick={() => {}}>
                                <DropdownItem
                                  tag="a"
                                  href="#"
                                  onClick={(ev) => {
                                    ev.preventDefault();
                                    setItemPerPage(10);
                                    let adt = getAdStatus();
                                    getWebData(adt, skey, 1, 10);
                                  }}
                                >
                                  <span>10</span>
                                </DropdownItem>
                              </li>
                              <li onClick={() => {}}>
                                <DropdownItem
                                  tag="a"
                                  href="#"
                                  onClick={(ev) => {
                                    ev.preventDefault();
                                    setItemPerPage(20);
                                    let adt = getAdStatus();
                                    getWebData(adt, skey, 1, 20);
                                  }}
                                >
                                  <span>20</span>
                                </DropdownItem>
                              </li>
                              <li onClick={() => {}}>
                                <DropdownItem
                                  tag="a"
                                  href="#"
                                  onClick={(ev) => {
                                    ev.preventDefault();
                                    setItemPerPage(50);
                                    let adt = getAdStatus();
                                    getWebData(adt, skey, 1, 50);
                                  }}
                                >
                                  <span>50</span>
                                </DropdownItem>
                              </li>
                              <li onClick={() => {}}>
                                <DropdownItem
                                  tag="a"
                                  href="#"
                                  onClick={(ev) => {
                                    ev.preventDefault();
                                    setItemPerPage(100);
                                    let adt = getAdStatus();
                                    getWebData(adt, skey, 1, 100);
                                  }}
                                >
                                  <span>100</span>
                                </DropdownItem>
                              </li>
                              <li onClick={() => {}}>
                                <DropdownItem
                                  tag="a"
                                  href="#"
                                  onClick={(ev) => {
                                    ev.preventDefault();
                                    setItemPerPage(500);
                                    let adt = getAdStatus();
                                    getWebData(adt, skey, 1, 500);
                                  }}
                                >
                                  <span>500</span>
                                </DropdownItem>
                              </li>
                            </ul>
                          </DropdownMenu>
                        </UncontrolledDropdown>
                      </div>
                    </div>
                    : '' }
                  </div>
                </Block>
  
                <ToastContainer />
              </Content>
            </>
          )}
        </React.Fragment>
  
        <Modal isOpen={modalTab} toggle={toggleTab} size="lg">
          <ModalHeader
            toggle={toggleTab}
            close={
              <button className="close" onClick={toggleTab}>
                <Icon name="cross" />
              </button>
            }
          >
            Generated Code
          </ModalHeader>
          {/* Old scrip tag for reference only do not uncomment */}
          {/* <ModalBody>
                  <CodeBlock language="jsx" title="COPY CODE">
                  {`<div id="`+adId+`" data-7pub="`+adId+`"></div>
                    <script src="https://code.adclickppc.com/`+adCode+`"></script>
                    <script>
                    (function() {
                      `+initCode+`(['`+adId+`', '`+adType+`'])
                      }())
                    </script>`}
                  </CodeBlock>
                </ModalBody> */}
          <ModalBody>
            {
              adType == 'banner' ?
              // <CodeBlock language="jsx" title="COPY CODE">
              //   {`<div id="` +
              //     adId +
              //     `" data-7pub="` +
              //     adId +
              //     `"></div>
              //         <script src="https://code.adclickppc.com/` +
              //     adCode +
              //     `"></script>
              //         <script>
              //         (function() {
              //           ` +
              //     initCode +
              //     `(['` +
              //     adId +
              //     `', '` +
              //     adType +
              //     `',  `+
              //     adSize +
              //     `])
              //           }())
              //         </script>`}
              // </CodeBlock>
              <CodeBlock language="jsx" title="COPY CODE">
                {`<div id="` +
                  adId +
                  `" data-7pub="` +
                  adId +
                  `"></div>
                      <script src="https://code.7searchppc.in/` +
                  adCode +
                  `"></script>
                      <script>
                      (function() {
                        ` +
                  initCode +
                  `(['` +
                  adId +
                  `', '` +
                  adType +
                  `',  `+
                  adSize +
                  `])
                        }())
                      </script>`}
              </CodeBlock>
              : adType == 'native' ?
              // <CodeBlock language="jsx" title="COPY CODE">
              //   {`<div id="` +
              //   adId +
              //   `" data-7pub="` +
              //   adId +
              //   `"></div>
              //       <script src="https://code.adclickppc.com/` +
              //   adCode +
              //   `"></script>
              //       <script>
              //       (function() {
              //         ` +
              //   initCode +
              //   `(['` +
              //   adId +
              //   `', '` +
              //   adType +
              //   `',  `+
              //   gridType +
              //   `])
              //         }())
              //       </script>`}
              // </CodeBlock>
              <CodeBlock language="jsx" title="COPY CODE">
                {`<div id="` +
                adId +
                `" data-7pub="` +
                adId +
                `"></div>
                    <script src="https://code.7searchppc.in/` +
                adCode +
                `"></script>
                    <script>
                    (function() {
                      ` +
                initCode +
                `(['` +
                adId +
                `', '` +
                adType +
                `',  `+
                gridType +
                `])
                      }())
                    </script>`}
              </CodeBlock>
              :
            //   <CodeBlock language="jsx" title="COPY CODE">
            //   {`<div id="` +
            //     adId +
            //     `" data-7pub="` +
            //     adId +
            //     `"></div>
            //         <script src="https://code.adclickppc.com/` +
            //     adCode +
            //     `"></script>
            //         <script>
            //         (function() {
            //           ` +
            //     initCode +
            //     `(['` +
            //     adId +
            //     `', '` +
            //     adType +
            //     `'])
            //           }())
            //         </script>`}
            // </CodeBlock>
              <CodeBlock language="jsx" title="COPY CODE">
              {`<div id="` +
                adId +
                `" data-7pub="` +
                adId +
                `"></div>
                    <script src="https://code.7searchppc.in/` +
                adCode +
                `"></script>
                    <script>
                    (function() {
                      ` +
                initCode +
                `(['` +
                adId +
                `', '` +
                adType +
                `'])
                      }())
                    </script>`}
            </CodeBlock>
            }
          </ModalBody>
        </Modal>
      </>
    );
  };
  
  export default Websites;
                          
