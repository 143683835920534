import React, { useState, useEffect, useContext } from "react";
import Content from "../../layout/content/Content";
import Head from "../../layout/head/Head";
import {Row, Col, Card, UncontrolledDropdown, DropdownToggle, DropdownMenu, Alert, Button, Badge, Table } from "reactstrap";
import {
  Block,
  PreviewCard,
  Icon,
  PaginationComponent,
  DataTable,
  DataTableHead,
  DataTableRow,
  DataTableItem,
  DataTableBody
} from "../../components/Component";
import { getWalletAmount, paymentHistory, userPayStatus } from "../../app/api";
import { Link } from "react-router-dom";
import Loader from "../../app/Loader";
import Moment from "react-moment";
import AppContext from "../../context/AppContext";
import { ToastContainer } from "react-toastify";

export default function Wallet() {
    const ctx = useContext(AppContext);
    const [loading, setLoading] = useState(false);
    const [wlt, setWlt] = useState(0);
    
    const [kycSts, setKycSts] = useState(8);
    const [paySts, setPaySts] = useState(1);
    const [payData, setPayData] = useState(null);
    const [upComingPay, setUpComingPay] = useState(null);


    const [tdate, setTdate] = useState(null);
    const apc = useContext(AppContext);
    const [wlog, setWlog] = useState(null);

    const [currentPage, setCurrentPage] = useState(1);
    const [itemPerPage, setItemPerPage] = useState(20);
    const [pgs, setPgs] = useState(0);

    const payStatus = async () => {
      setLoading(true)
      const uid = localStorage.getItem('uid');
      const res = await userPayStatus({uid:uid});
      // console.log(res.data);
      setKycSts(res.data.kyc_status);
      setPaySts(res.data.pay_mode_status);
      setPayData(res.data);
      setUpComingPay(res.data.upcoming_pay_list);
      // console.log(res.upcoming_pay_list);

      if(res.data) {
        apc.dispatch({
          type:'wlt',
          wlt: res.data.avalable_amt
        });
      }
      setLoading(false);

    }

    // const getWltAmount = async () => {
    //   setLoading(true)
    //   const uid = localStorage.getItem('uid');
    //   const res = await getWalletAmount(uid);
    //   setWlt(res.wallet);
    //   localStorage.setItem("wlt", res.wallet);
     
    //   setLoading(false)
    // }

    const getPaymentLog = async (pg = 1) => {
      setLoading(true)
      let uid = localStorage.getItem('uid');
      let res = await paymentHistory({
        uid:uid,
        page:pg,
        lim:itemPerPage
      });
      if(res) {
        setWlog(res.data);
        setPgs(res.row);
      }
      setLoading(false)
    }

    const [mhover, seMhover] = useState(false);
    
    const handleHover = () => { seMhover(!mhover) }

    const paginate = (pageNumber) => {
      setCurrentPage(pageNumber);
      if(currentPage !== pageNumber) {
        getPaymentLog(pageNumber)
      }
    }
    const utype = localStorage.getItem('utype');

    useEffect(()=>{
      if(utype == 1) {}
      // getWltAmount();
      getPaymentLog();
      payStatus();
    },[]);

    return (
        <React.Fragment>
        <Head title="My Wallet" />
        
        {utype == 1 ?
        <Content page="component" >
          <Block size="lg" className="pt-5">
            <PreviewCard>
              <Row className="gy-4">
                <Col sm="12">
                <div className="card-amount">
                <span className="amount text-primary mb-3" style={{fontSize:18}}>
                  </span>
                  
                </div>
                <div className="card-title text-center">
                  <h2>404 </h2>
                  <h5 className="titel">Page Not Found</h5>
                </div>
                </Col>
              </Row>
            </PreviewCard>
          </Block>
        </Content>
        :
        <Content page="component">
          <Block size="lg">
          <Loader visible={loading} />
          {kycSts == 0 &&
          <Alert className="alert-icon alert-fill" color="danger" >
            <Icon name="alert" />
            You have not uploaded your documents for the KYC. <Link to="user-kyc" className="alert-link">Click Here</Link> to upload your documents.
          </Alert>
          }
          {kycSts == 1 && 
          <Alert className="alert-icon alert-fill" color="dark">
            <Icon name="alert" />
            Thank you for your KYC submission. Your details are under review, and we'll notify you once the process is complete.
          </Alert>
          }
           {kycSts == 3  &&
          <Alert className="alert-icon alert-fill" color="danger">
            <Icon name="alert" />
             Your KYC submission has been rejected. Kindly review the reason for rejection and re-upload the necessary documents.
          </Alert>
          }
          {paySts == 0 &&

          <Alert className="alert-icon alert-fill" color="info">
            <Icon name="alert" />
            You have not set any payment method. <Link to="user-payment-method" className="alert-link">Click Here</Link> to update payment method.
          </Alert>
          }     

            <PreviewCard>
              <Row className="gy-4">
                <Col sm="12">
                <div className="card-amount">
                  <span className="amount text-primary mb-3" style={{fontSize:18}}>
                    Payment Rule
                  </span>
                  
                </div>
                <div className="card-title">
                    <div className="subtitle text-dark">Payment dates are the <b>1<sup>st</sup>  and 15<sup>th</sup></b> of each month, from 9.00 AM till 6.00 PM GMT.
                    If these dates fall on weekends or holidays the payments will be processed on the nearest business day.
                    Once you reach the minimum payout amount, the money is put on a two-week hold.
                    You will get the payout during the first payment period available after your hold period ends.</div>
                  </div>
                <div className="invest-data">
                  <div className="invest-data-amount g-2">
                    <div className="invest-data-history">
                    <div className="card-title">
                      <div className="subtitle">
                        {tdate !== null && <span><Icon name="calendar-fill" style={{fontSize:'16px', marginTop:'15px'}} /> Your last payment was on {tdate}</span>}
                        
                      </div>
                    </div>
                    </div>
                  </div>
                </div>
                </Col>
              </Row>
            </PreviewCard>
          </Block>
          {ctx.sts.perms.includes('432') || ctx.sts.perms.includes('9999') ?
          <Block size="lg" className="pt-5">
            <PreviewCard>
              <Row className="gy-4">
                <Col sm="6">
                <div className="card-amount">
                <span className="amount text-primary mb-3" style={{fontSize:18}}>
                    Payment Details
                  </span>
                  
                </div>
                <div className="card-title">
                <div className="amount text-dark" style={{fontSize:16}}>
                    <span className="mr-5">Total Earnings </span><span className="ml-5">${payData ? payData.total_earning : 0}</span>
                  </div>
                  <div className="amount text-dark" style={{fontSize:16}}>
                    <span className="mr-4">Total Withdrawal </span><span className="ml-5"> &nbsp;${payData ? payData.total_withdrawl : 0}</span>
                  </div>
                  <div className="amount text-dark fw-bold mt-2 " style={{fontSize:16}}>
                  <span className="mr-3">Available Balance</span><span className="ml-5">&nbsp;{' '}${payData ? payData.avalable_amt : 0}</span>
                  </div>
                </div>
                </Col>
              </Row>
            </PreviewCard>
          </Block>
          : ''}
          {ctx.sts.perms.includes('434') || ctx.sts.perms.includes('9999') ?
          <Block size="lg" className="pt-5">
              <Row className="gy-4">
                <Col sm="12">
                <PreviewCard>
                <div className="card-inner-group">
                    <div className="card-inner">
                      <div className="card-title-group">
                        <div className="card-title">
                          <h5 className="title">Upcoming Payments</h5>
                          <div className="subtitle">
                              {/* <span>Last 10 payment record</span> */}
                              
                            </div>
                        </div>
                        <div className="card-tools mr-n1">
                          
                        </div>
                      </div>
                    </div>
                  <div className="mb-3 table-responsive">
                  <DataTableBody className="border-top" bodyclass="nk-tb-orders pr-0 pl-0">
                    <DataTableHead bgColor="#fff"> 
                      <DataTableRow>
                        <span><b>Release Date</b></span>
                      </DataTableRow>
                      <DataTableRow>
                        <span>   <b>Transaction ID</b></span>
                      </DataTableRow>
                      <DataTableRow>
                        <span> <b>Amount</b></span>
                      </DataTableRow>
                      <DataTableRow className="text-right">
                        <span><b>Status</b></span>
                      </DataTableRow>
                    </DataTableHead>
                    {upComingPay && upComingPay.map((item, key)=>{

                      return (
                        <DataTableItem >
                          <DataTableRow>
                            <span className="tb-sub text-muted">{item.release_date}</span>
                          </DataTableRow>
                          <DataTableRow>
                            <span className="text-muted">{item.transaction_id}  </span>
                          </DataTableRow>
                          <DataTableRow>
                                <span className="tb-sub text-muted"> ${item.amount.toFixed(2)} </span>
                          </DataTableRow>
                          <DataTableRow className="text-right">
                            <span className="tb-sub tb-amount">
                              <span>{item.status == 0 ? <Badge color="info" className="text-light">Process</Badge> : <Badge color="warning" className="text-dark">Hold</Badge>}</span>
                            </span>
                          </DataTableRow>
                        </DataTableItem>
                      )
                    })}
                  </DataTableBody>
                </div> 
                </div>
              </PreviewCard>
            </Col>
              </Row>
          </Block>
          : '' }
          {ctx.sts.perms.includes('436') || ctx.sts.perms.includes('9999') ?
          <Block size="lg" className="pt-5">
            <PreviewCard>
              <Row className="gy-4">
                <Col sm="12">
                <div className="card-amount">
                <span className="amount text-primary mb-3" style={{fontSize:18}}>
                   Current Payment Method
                  </span>
                  
                </div>
                <div className="card-title">
                <div className="amount text-dark" style={{fontSize:16}}>
                    {payData && payData.payout_mode.length > 0  
                    ?
                    <h6 className="title">
                      <img
                        src={payData.image}
                        alt="sign-paypal-alt"
                        style={{
                          maxWidth: "100%",
                          height: "auto",
                          width: "27px",
                          maxHeight: "27px",
                        }}
                      /> &nbsp; {payData.display_name}
                      {/* <Badge color="light">Default</Badge> */}
                      <Link to="user-payment-method"  className="btn btn-sm btn-primary float-right">Change</Link>
                    </h6>
                    :
                    'No Payment Method Selected'
                    }
                  </div>
                </div>
                </Col>
              </Row>
            </PreviewCard>
          </Block>
         :''}
         {ctx.sts.perms.includes('438') || ctx.sts.perms.includes('9999') ?
          <Block size="lg" className="pt-5">
            <Row className="gy-4">
                <Col sm="12">

                <PreviewCard>
            <div className="card-inner-group">
              <div className="card-inner">
                <div className="card-title-group">
                  <div className="card-title">
                    <h5 className="title">Transaction History</h5>
                    <div className="subtitle">
                         {/* <span>Last 10 payment record</span> */}
                        
                      </div>
                  </div>
                  <div className="card-tools mr-n1">
                    
                  </div>
                </div>
              </div>
              <div className="card-inner p-0 table-responsive">
                <table className="table table-tranx">
                  <thead>
                    <tr style={{background:"#3b82f6",color:"#fff"}}>
                      <th>
                        <span>Transaction Date</span>
                      </th>
                      <th>Transaction ID</th>
                      <th>Payment Mode</th>
                      <th>Paid Amount</th>
                      <th className="text-right">Status</th>
                    </tr>
                  </thead>
                  <tbody>
                    {wlog != null
                      ? wlog.map((item) => {
                          return (
                            
                            <tr key={item.id}>
                              <td className="tb-tnx-info">
                                    <Moment format="DD MMM YYYY">
                                    {item.release_created_at ? item.release_created_at : item.release_date}
                                    </Moment>
                                </td>
                                <td className="text-muted"> {item.transaction_id} </td> 
                                <td className="text-muted">{item.payout_method}</td>
                                <td className="fw-bold">${item.amount.toFixed(2)}</td>
                                <td className="text-right">
                                  <span className={`badge badge-dot badge-${
                                      item.status == 1 ? "success" : item.status == 2 ?  "danger" : 'warning'
                                    }`} >
                                    {item.status == 1 ? 'COMPLETED' :  item.status == 2 ? 'FAILED' : 'PENDING'}
                                  </span>
                                </td>
                            </tr>
                          );
                        })
                      : null}
 
                  </tbody>

                </table>
                    <div className="card-inner" style={{display:'flex'}}>
                        <div style={{alignSelf:'self-start', width:'97%'}}>
                          
                        {wlog && wlog.length ? (
                          <PaginationComponent
                          itemPerPage={itemPerPage}
                          totalItems={pgs}
                          paginate={paginate}
                            currentPage={currentPage}
                          />
                        ) : (
                          <div className="text-center">
                            <span className="text-silent">No transaction found</span>
                          </div>
                        )}
                        </div>
                      </div>
              </div>
             
            </div>
          </PreviewCard>

                  {/* <Card className="card-bordered">
                    <CardHeader className="border-bottom"></CardHeader>
                    <CardBody className="card-inner">
                    <table className="table table-borderless">  
                     
                        <tbody>   
                          {wlog !== null ? 
                            wlog.map((item)=>{
                             return <tr>          
                                  <td>{item.date}</td>      
                                  <td className="text-right">${item.amount}</td>      
                              </tr>  
                            })  
                            : '' }
                           
                        </tbody>
                    </table>

                    </CardBody>
                  </Card> */}
                    {/* <PreviewCard>
                    </PreviewCard> */}
                </Col>
            </Row>
          </Block>
          :''}
        </Content>
        }
        <ToastContainer />
      </React.Fragment>
    )
}